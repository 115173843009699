export default function Button(props) {
    const {
        title,
        buttonClassName
    } = props;

    return (
        <button 
            className={`bg-primary text-light pals-button ${buttonClassName}`}
            {...props}
        >
            {title || 'Button'}
        </button>
    )
}