import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Footer() {
	return (
		<>
			<Container>
				<Row className="my-2rem">
					<Col xs={12} md={6} className="fs-6 text-center text-md-start text-dark">
						2021 Pals App LLC. All rights reserved.
					</Col>
					<Col xs={12} md={6} className="fs-6 text-center text-md-end">
						<Link to="/privacy" className="text-dark text-decoration-none">Privacy Policy</Link> | <Link to="/terms" className="text-dark text-decoration-none">Terms of Service</Link>
					</Col>
				</Row>
			</Container>
		</>
	);
}
