import { Col, Row, Image } from "react-bootstrap";
import CheckMark from "../../../assets/svgs/Check.png";
import blueCircle from "../../../assets/images/blue_circle_graphic.png";
import mapCheckmyPlaces from "../../../assets/images/map_check_my places_mockup.png";
import { Element } from 'react-scroll'

export default function OurMission() {
    return (
        <Element name="mission">
            <Row className="mt-5">
                <Col sm={12} md={12} lg={5} className="my-auto">
                    <div className="text-start head-text fw-bolder">OUR MISSION</div>
                    <div className="text-start sub-head-text mb-2">Every Dog Needs a Pal</div>
                    <div className="text-start sub-text-peragraph">Do you find yourself taking the same route every time you go for a walk with your dog? Is your dog constantly begging to play or go outside? With Pals, we make it easy for you to find other dogs in your area looking to meet, play, run, walk, swim and so much more! Join the pals community today and experience for yourself!</div>
                    <div className="margin-top-37" />
                    <div className="our-mission-list">
                        <Image className="check-mark" height={34} width={34} src={CheckMark} />
                        <div>
                            Filter by Size, Breed or Gender
                        </div>
                    </div>
                    <div className="our-mission-list"><Image className="check-mark" height={34} width={34} src={CheckMark} />Add new locations to the map for your Pal Community</div>
                    <div className="our-mission-list"><Image className="check-mark" height={34} width={34} src={CheckMark} />Highlights favorite activities and personality traits</div>
                </Col>
                <Col sm={12} md={12} lg={7}>
                    <div className="position-relative d-flex justify-content-center align-items-center">
                        <Image className="blue-circle" src={blueCircle} />
                        <Image className="blue-circle-inner-image" src={mapCheckmyPlaces} />
                    </div>
                </Col>
            </Row>
        </Element>
    )
}