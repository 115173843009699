import React from 'react';
import { Col, Row } from 'react-bootstrap';

export default function TitleBanner(props) {
    const {
        title,
        description,
        containerClassName
    } = props;

    return (
        <Row>
            <Col className="p-0">
                <div className={`title-banner-container flex-column ${containerClassName}`}>
                    <div>{title}</div>
                    {
                        description ? (
                            <div className="thank-you-text text-dark">
                                {description}
                            </div>
                        ) : null
                    }
                </div>
            </Col>
        </Row>
    )
}