function Helper(url, method, body) {
    return fetch(url, {
            method,
            body: method === 'GET' ? undefined : JSON.stringify(body),
            mode: "no-cors",
            headers: {
                // 'Content-Type': 'application/json',
                // 'mode': 'no-cors'
            }
        })
        .then((response) => response.json())
        .catch((error) => {})
}

export default Helper;