import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Element } from 'react-scroll'
import { ContactFormUrl } from "../../../consts/config";
import Helper from "../../../consts/helper";

export default function ContactForm() {
    const [contactData, setContactData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [isValidated, setIsValidated] = useState(false);
    const [errors, setErrors] = useState({});

    const history = useHistory();

    useEffect(() => {
        if (isValidated) {
            checkValidation();
        }
    }, [contactData, isValidated]);

    return (
        <Element name="contact" className="px-3px">
            <Row className="d-flex justify-content-center mx-auto mb-5 form-bg-color px-3 px-md-5 contact-form-container">
                <Col sm={8} className="form-header-text text-start text-md-center">
                    Let’s Connect
                </Col>
                <Col sm={9} className="form-subtext text-start text-md-center">
                    Whether you’re curious about our special features or want to share your excitement with the Pals team, we’re ready to answer any and all questions! Fill out the form below to get the ball rolling!
                </Col>
                <Col sm={12} lg={6} className="text-center text-md-start">
                    <div className={`${errors?.name ? '' : 'v-none'}`}>Name Required*</div>
                    <input
                        className="form-input my-auto px-4"
                        placeholder="NAME*"
                        name="name"
                        onChange={handleUpdate}
                        required
                    />
                </Col>
                <Col sm={12} lg={6} className="text-center text-md-end">
                    <div className={`${(errors?.email || errors?.emailValid) ? '' : 'v-none'}`}>{errors?.email ? '' : errors?.emailValid ? 'Valid ' : ''}Email Required*</div>
                    <input
                        className="form-input my-auto px-4"
                        placeholder="EMAIL*"
                        name="email"
                        type="email"
                        onChange={handleUpdate}
                        required
                    />
                </Col>
                <Col sm={12} className="mt-0 mt-lg-3">
                    <div className={`${errors?.message ? '' : 'v-none'}`}>Message Required*</div>
                    <textarea
                        className="form-message-input px-4 py-4 my-auto"
                        placeholder="MESSAGE*"
                        name="message"
                        onChange={handleUpdate}
                        required
                    />
                </Col>
                <Col sm={12} className="d-flex justify-content-center">
                    <Button type="button" onClick={submitContactForm} className={`form-button ${checkValidation(false)?.validated ? 'form-button-valid' : ''}`}>SUBMIT</Button>
                </Col>
            </Row>
        </Element>
    )

    function checkValidation(isSetErrors = true) {
        let validated = true;

        const errors = {
            name: false,
            message: false,
            email: false,
            emailValid: false
        };
        if (!contactData.name) {
            validated = false;
            errors.name = true;
        }
        if (!contactData.email) {
            validated = false;
            errors.email = true;
        }
        if (!contactData.message) {
            validated = false;
            errors.message = true;
        }
        if (!contactData.email?.includes('@') || contactData.email?.split('@')?.length <= 1 || !(contactData?.email?.split('@')[1]?.length >= 1)) {
            validated = false;
            errors.emailValid = true;
        }
        if (isSetErrors) {
            setIsValidated(true);
            setErrors(errors);
        }
        return {
            validated,
            errors
        };
    }
    function handleUpdate(e) {
        const { name, value } = e.target;
        setContactData({
            ...contactData,
            [name]: value
        });
    }
    async function submitContactForm(e) {
        e.preventDefault();
        try {
            const validationRes = checkValidation();
            if (validationRes?.validated) {
                const response = await Helper(ContactFormUrl, 'POST', {
                    ...contactData
                });
                if (response?.error) {
                    // something went wrong while saving the form
                }
                else {
                    history.push('/thanks');
                }
            }
        }
        catch (error) {
            // something went wrong while saving the form
        }
    }
}