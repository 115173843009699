import {
    useEffect
} from "react";
import {
    useHistory,
    useLocation
} from "react-router-dom/cjs/react-router-dom.min";

export default function RouteProvider(props) {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (location.pathname === '/landing_page' && location.hash) {
            history.push(`/${location.hash}`);
        }
    }, [location, history]);

    return props.children;
}