import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import FollowUs from '../../consts/common/FollowUs';
import Header from '../../consts/common/Header';
import PrefooterLiveFeed from '../../consts/common/PrefooterLiveFeed';

// components
import TitleBanner from '../../consts/common/TitleBanner';
import privacyTermsData from '../../consts/privacyTermsData';

export default function Policy(props) {

    const { type } = props;

    return (
        <Container fluid>
            <Header />
            <TitleBanner
                title={type === 'terms' ? 'TERMS OF SERVICE' : 'PRIVACY POLICY'}
            />
            <Container>
                <Row>
                    <Col>
                        <div className="p-3 p-md-5 policy-text">
                            <div dangerouslySetInnerHTML={{
                                __html: privacyTermsData[type]
                            }}>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="mb-3">
                <FollowUs />
            </div>
            <PrefooterLiveFeed />
        </Container>
    )
}