import * as React from "react"

function CommentSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 106.95 95.08"
            height={42}
            width={42}
            {...props}
        >
            <g data-name="Layer 2">
                <path
                    d="M65.36 53.46H30.13L14.76 65a1.8 1.8 0 01-2.88-1.45V53.48A11.9 11.9 0 010 41.59V11.88A11.89 11.89 0 0111.88 0h53.48a11.89 11.89 0 0111.88 11.88v29.71a11.87 11.87 0 01-11.88 11.87zm29.71-23.75H83.18v11.88a17.85 17.85 0 01-17.82 17.83H35.65V71.3a11.89 11.89 0 0011.88 11.88h23.34l15.38 11.53a1.8 1.8 0 002.88-1.44V83.18h5.94A11.89 11.89 0 00107 71.3V41.59a11.89 11.89 0 00-11.93-11.88z"
                    fill="#a0a"
                    data-name="Layer 1"
                    className={'about-icon'}
                />
            </g>
        </svg>
    )
}

export default CommentSvg
