import React from 'react';
import InstagramLogo from '../../assets/images/instagram_logo_blue_circle.png';
import { Col, Image, Row } from 'react-bootstrap';
import colors from '../colors';

export default function FollowUs(props) {
    const {
        containerClassName,
        color,
    } = props;
    return (
        <Row>
            <Col sm={12} className="text-center text-md-end my-auto">
                <div
                    onClick={() => {
                        window.open('https://www.instagram.com/palsapp/?hl=en', '_blank');
                    }}
                    className={`cursor-pointer follow-us-instagram ${containerClassName} `}
                    style={{
                        color: color || colors.dark
                    }}
                >
                    Follow Us on Instagram!
                    <Image className="instagram-logo ms-0 ms-md-4" src={InstagramLogo} />
                </div>
            </Col>
        </Row>
    );
}