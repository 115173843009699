import { Col, Row } from "react-bootstrap";
import { Element } from 'react-scroll';
import Button from "../../../consts/common/Button";

export default function AboutPals() {
    return (
        <Element name="donate" className="mt-5 mt-lg-5">
            <Row className="mt-5">
                <Col sm={12} className="text-center fw-bold head-text">DONATE</Col>
                <Col sm={12} className="text-center sub-head-text">Support AWLA</Col>
                <Col sm={12} className="text-center sub-text-peragraph">We will be collecting items to donate to the Animal Welfare League of Arlington (AWLA). </Col>
                <Col sm={12} className="text-center sub-text-peragraph mb-30px" style={{ marginTop: '0px' }}> Interested in donating? Check out the AWLA wishlist,&nbsp;
                  <a href="https://www.awla.org/donate/other-ways-to-give/gifts-in-kind/" rel="noreferrer" target="_blank" className="text-dark">here.</a>
                </Col>
                <Col className="text-center">
                    <Button
                        title="LEARN MORE"
                        onClick={() => {
                            window.open('https://www.awla.org/', '_blank')
                        }}
                        buttonClassName="mx-auto"
                    />
                </Col>
            </Row>
        </Element>
    )
}