import { Col, Row, Image } from "react-bootstrap";
import BlueCircle from "../../../assets/images/blue_circle_graphic.png";
import PalBandana from "../../../assets/images/pals_bandana_mockup.png";
import { Element } from 'react-scroll';

export default function Merch() {
    return (
        <Element name="merch">
            <Row className="mt-5 flex-direction-reverse">
                <Col sm={12} md={12} lg={5} className="my-auto">
                    <div className="text-start head-text fw-bolder">MERCH</div>
                    <div className="text-start sub-head-text mb-2">Pals Flex Bandana</div>
                    <div className="text-start sub-text-peragraph">This lightweight, durable and vibrant bandana is perfect for playdates and quickly spotting your pal at the park. It’s flex fabric can even withstand tug-o-war!</div>
                    <div className="text-start merch-available-soon-text mb-5 mb-lg-0">Available soon for purchase!</div>
                </Col>
                <Col sm={12} md={12} lg={7}>
                    <div className="position-relative d-flex justify-content-center align-items-center">
                        <Image className="blue-circle" src={BlueCircle} />
                        <Image className="blue-circle-inner-image" src={PalBandana} />
                    </div>
                </Col>
            </Row>
        </Element>
    )
}