import { Navbar, Container, Nav, Image, Row } from 'react-bootstrap';
import PalsLogo from '../../assets/images/pals_logo.png';
import InstaLogo from '../../assets/images/instagram_logo_blue_circle.png';
import { Link } from 'react-router-dom';
import MenuIcon from '../../assets/svgs/menu_icon.svg';
import { useState } from 'react';

export default function Header(props) {

    const [expanded, setExpanded] = useState(false);

    const {
        transparent,
        sticky = false
    } = props;

    return (
        <Row
            className={`${transparent ? 'bg-transparent' : 'bg-primary'} ${!sticky ? 'pt-4' : 'sticky-navbar'}  sticky-navbar-mobile`}
            style={{
                height: transparent && !expanded ? '0px' : 'auto'
            }}
        >
            <Navbar collapseOnSelect expand="lg" variant="light" expanded={expanded}>
                <Container>
                    <Navbar.Brand>
                        <Link to="/">
                            <Image src={PalsLogo} className="header-logo navbar-logo-hover" />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle onClick={() => { setExpanded(previous => !previous) }} aria-controls="responsive-navbar-nav" >
                        {
                            expanded ? (
                                <i className="fas fa-times text-white fa-xl"></i>
                            ) : (
                                <Image src={MenuIcon} />
                            )
                        }
                    </Navbar.Toggle>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link className="header-menu-container" onClick={() => { setExpanded(false) }}>
                                <Link className="header-menu-item text-decoration-none" to="/#about">

                                    ABOUT
                                </Link>
                            </Nav.Link>
                            <Nav.Link className="header-menu-container" onClick={() => { setExpanded(false) }}>
                                <Link className="header-menu-item text-decoration-none" to="/#mission">
                                    MISSION
                                </Link>
                            </Nav.Link>
                            <Nav.Link className="header-menu-container" onClick={() => { setExpanded(false) }}>
                                <Link className="header-menu-item text-decoration-none" to="/#merch">
                                    MERCH
                                </Link>
                            </Nav.Link>
                            <Nav.Link className="header-menu-container" onClick={() => { setExpanded(false) }}>
                                <Link className="header-menu-item text-decoration-none" to="/#donate">
                                    DONATE
                                </Link>
                            </Nav.Link>
                            <Nav.Link className="header-menu-container" onClick={() => { setExpanded(false) }}>
                                <Link className="header-menu-item text-decoration-none" to="/#contact">
                                    CONTACT
                                </Link>
                            </Nav.Link>
                            <Nav.Link href="https://www.instagram.com/palsapp/?hl=en" target="_blank" className="header-menu-item d-flex justify-content-center" style={{ marginTop: '0px' }}>
                                <Image src={InstaLogo} width={60} />
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Row>
    )
}