import { Row, Col, Image, Button } from "react-bootstrap";
import discoverMatchMockup from '../../../assets/images/discover_match_mockup.png';

export default function Banner() {
    return (
        <>
            <Row className="banner-container">
                <Col sm={12} lg={5}>
                    <div className="d-flex justify-content-end banner-mobile-container">
                        <Image className="img-fluid banner-mockup-image" src={discoverMatchMockup} />
                    </div>
                </Col>
                <Col sm={12} lg={7} className="mt-5 offset-0" >
                    <div className="top-banner-subtext-caption">WOOHOO! THE WAIT IS FINALLY OVER.</div>
                    <div className="top-banner-heading">GET THE PALS APP</div>
                    <div className="bg-dark mt-2 top-banner-subtext">
                        The Pals mobile app is officially live and available for iOS and Android users. Select from the options below to download and start using Pals today! 
                    </div>
                    <Col className="text-center text-md-start banner-btn-container">
                        {/* TODO bring back when we have demo video */}
                        <Button
                            className="mt-4 custom-btn"
                            onClick={() => {
                                window.open('https://apps.apple.com/us/app/pals-sit-swipe-play/id1580154158', '_blank')
                            }}
                            buttonClassName="mx-auto"
                        >VISIT APP STORE</Button> 
                        {/* TODO bring back when we have demo video */}
                        <Button
                            className="mt-4 ms-0 ms-md-3 custom-btn"
                            onClick={() => {
                                window.open('https://play.google.com/store/apps/details?id=com.voloapps.palsapp', '_blank')
                            }}
                            buttonClassName="mx-auto"
                        >VISIT GOOGLE PLAY</Button> 
                    </Col>
                </Col>
            </Row>
        </>
    )
}