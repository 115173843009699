import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import colors from '../../consts/colors';

// components
import PrefooterLiveFeed from '../../consts/common/PrefooterLiveFeed';
import FollowUs from '../../consts/common/FollowUs';
import Header from '../../consts/common/Header';
import TitleBanner from '../../consts/common/TitleBanner';

export default function ThanksContact() {
    return (
        <Container fluid>
            <Header />
            {/* <TitleBanner
                title={"THANK YOU!"}
                description="Thanks for contacting Pals. A member from our team will reach out to you shortly!"
                containerClassName="thank-you-container"
            /> */}
            <Row>
                <Col lg={12} className="home-page-back" style={{ minHeight: '82vh' }}>
                    <div className="home-launch-text mt-5">
                        THANK YOU!
                    </div>
                    <Container>
                        <div className="thank-you-text text-dark d-none d-md-block">
                            <div>Thanks for contacting Pals. A member from our team will</div>
                            <div>reach out to you shortly!</div>
                        </div>
                        <div className="thank-you-text text-dark d-flex d-md-none">
                            <div>Thanks for contacting Pals. A member from our team will reach out to you shortly!</div>
                        </div>
                    </Container>
                </Col>
                <Col lg={12} className="home-follow-us">
                    <FollowUs color={colors.light} />
                </Col>
            </Row>
            <PrefooterLiveFeed />
        </Container>
    )
}



