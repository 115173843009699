import { Container, Image } from "react-bootstrap";
import backgroundEllipse from "../../assets/images/background_ellipse.png";
import AboutPals from "./components/AboutPals";
import Banner from "./components/Banner";
import BrandCarousel from "./components/BrandCarousel";
import Merch from "./components/Merch";
import OurMission from "./components/OurMission";
import ContactForm from "./components/ContactForm";
import FollowUs from "../../consts/common/FollowUs";
import Donate from "./components/Donate";
import PrefooterLiveFeed from "../../consts/common/PrefooterLiveFeed";
import Header from "../../consts/common/Header";
import { useState, useEffect } from "react";
import { scroller } from "react-scroll";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import Helper from "../../consts/helper";


export default function LandingPage() {
  const location = useLocation();

  const infValue = new URLSearchParams(location.search).get("inf");

  //creating function to load ip address from the API
  const getData = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");
      const ipv4 = res.data.IPv4;

      const dataObj = {
        id: infValue,
        ip: ipv4,
        data: JSON.stringify(res.data),
      }

      const response = await Helper("https://api.getpalsapp.com/log-influencer", 'POST', dataObj);
    } catch (error) {}
  };

  useEffect(() => {
    if (infValue) {
      getData();
    }
  }, [infValue]);

  useEffect(() => {
    if (location.hash) {
      scroller.scrollTo(location.hash.split("#")[1], {
        duration: 500,
        delay: 0,
        smooth: true,
        offset: -100,
      });
    }
  }, [location]);

  useEffect(() => {
    const navbar = document.querySelector(".sticky-navbar");
    const logoNavbar = document.querySelector(".header-logo");
    if (navbar) {
      window.onscroll = () => {
        if (window.scrollY > 200) {
          navbar.classList.add("sticky-navbar-active");
          logoNavbar.classList.add("navbar-logo-hover");
        } else {
          navbar.classList.remove("sticky-navbar-active");
          logoNavbar.classList.remove("navbar-logo-hover");
        }
      };
    }
  }, []);

  return (
    <Container fluid className="overflow-x-hidden">
      <Image
        className="img-fluid background-ellipse-position"
        src={backgroundEllipse}
      />
      <Header transparent sticky />
      <Container className="position-relative">
        <Banner />
        <BrandCarousel />
        <AboutPals />
        <OurMission />
        <Merch />
        <Donate />
        <ContactForm />
        <div className="mt-5 pt-5">
          <FollowUs />
        </div>
      </Container>
      <div className="mt-5">
        <PrefooterLiveFeed />
      </div>
    </Container>
  );
}
