import { Image, Nav } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

// logos
import VoloLogo from '../../../assets/images/Volo.jpg';
import AWLALogo from '../../../assets/images/AWLA.jpg';
import BoxLogo from '../../../assets/images/Boxed Sourcing.jpg';
import CustominkLogo from '../../../assets/images/Custom Ink.jpg';
import TentcraftLogo from '../../../assets/images/TentCraft.jpg';

SwiperCore.use([Navigation]);

export default function BrandCarousel() {
    return (
        <Swiper
            id="brand-swiper"
            className="mt-5"
            spaceBetween={5}
            slidesPerView={5}
            navigation={true}
            breakpoints={{
                300: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 5,
                }
            }}
        >
            <SwiperSlide tag="li" style={{ listStyle: 'none' }}>
                <Nav.Link href="https://voloapps.com" target="_blank">
                    <Image
                        style={{ width: '100%' }}
                        src={VoloLogo}
                        alt={`Slide`}
                    />
                </Nav.Link>
            </SwiperSlide>
            <SwiperSlide tag="li" style={{ listStyle: 'none' }}>
                <Nav.Link href="https://www.awla.org/" target="_blank">
                    <Image
                        style={{ width: '100%' }}
                        src={AWLALogo}
                        alt={`Slide`}
                    />
                </Nav.Link>
            </SwiperSlide>
            <SwiperSlide tag="li" style={{ listStyle: 'none' }}>
                <Nav.Link href="https://www.boxedsourcing.com/" target="_blank">
                    <Image
                        style={{ width: '100%' }}
                        src={BoxLogo}
                        alt={`Slide`}
                    />
                </Nav.Link>
            </SwiperSlide>
            <SwiperSlide tag="li" style={{ listStyle: 'none' }}>
                <Nav.Link href="https://www.customink.com/" target="_blank">
                    <Image
                            style={{ width: '100%' }}
                            src={CustominkLogo}
                            alt={`Slide`}
                    />
                </Nav.Link>
            </SwiperSlide>
            <SwiperSlide tag="li" style={{ listStyle: 'none' }}>
                <Nav.Link href="https://www.tentcraft.com/" target="_blank">
                    <Image
                        style={{ width: '100%' }}
                        src={TentcraftLogo}
                        alt={`Slide`}
                    />
                </Nav.Link>
            </SwiperSlide>
        </Swiper>
    )
}