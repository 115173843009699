import React from 'react';
import { Image, Row, Col } from 'react-bootstrap';
import ScreenShot1 from '../../assets/images/instagram_sc_1.png';
import ScreenShot2 from '../../assets/images/instagram_sc_2.png';
import ScreenShot3 from '../../assets/images/instagram_sc_3.png';
import ScreenShot4 from '../../assets/images/instagram_sc_4.png';

export default function PrefooterLiveFeed() {
    return (
        <Row className="mb-2">
            <Col className="p-0" sm={6} lg={3}>
                <Image className="img-fluid w-100" src={ScreenShot1} />
            </Col>
            <Col sm={6} lg={3} className="p-0">
                <Image className="img-fluid" src={ScreenShot2} />
            </Col>
            <Col className="p-0" sm={6} lg={3}>
                <Image className="img-fluid" src={ScreenShot3} />
            </Col>
            <Col sm={6} lg={3} className="p-0">
                <Image className="img-fluid" src={ScreenShot4} />
            </Col>
        </Row>
    );
}