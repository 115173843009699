const data = {
    privacy: `
    <p>
        Thank you for choosing to be part of our community at Pals LLC ("Company", "we", "us", "our"). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at support@getpalsapp.com.
    </p>

    <p>
        When you use our mobile application, as the case may be (the <span class="fw-bold">"App"</span>) and more generally, use any of our services (the "Services", which include the App), we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.
    </p>

    <p>
        This privacy notice applies to all information collected through our Services (which, as described above, includes our App), as well as, any related services, sales, marketing or events.
    </p>

    <p class="fw-bold">
        Please read this privacy notice carefully as it will help you understand what we do with the information that we collect.
    </p>


    <div class="mt-5 fw-bold">
        TABLE OF CONTENTS
    </div>
    <ol>
        <li>
            WHAT INFORMATION DO WE COLLECT?
        </li>
        <li>
            HOW DO WE USE YOUR INFORMATION?
        </li>
        <li>
            WILL YOUR INFORMATION BE SHARED WITH ANYONE?
        </li>
        <li>
            HOW LONG DO WE KEEP YOUR INFORMATION?
        </li>
        <li>
            HOW DO WE KEEP YOUR INFORMATION SAFE?
        </li>
        <li>
            WHAT ARE YOUR PRIVACY RIGHTS?
        </li>
        <li>
            CONTROLS FOR DO-NOT-TRACK FEATURES
        </li>
        <li>
            DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </li>
        <li>
            DO WE MAKE UPDATES TO THIS NOTICE?
        </li>
        <li>
            HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </li>
        <li>
            HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?
        </li>
    </ol>
    <div class="mt-5 fw-bold">
        1. WHAT INFORMATION DO WE COLLECT?
    </div>
    <p>
        <span class="fw-bold">Personal information you disclose to us</span>
    </p>
    <p>
        <span class="fw-bold">In Short:</span>  We collect personal information that you provide to us.
    </p>
    <p>
        We collect personal information that you voluntarily provide to us when you register on the App, express an interest in obtaining information about us or our products and Services, when you participate in activities on the App (such as by posting messages in our online forums or entering competitions, contests or giveaways) or otherwise when you contact us.
    </p>
    <p>
        The personal information that we collect depends on the context of your interactions with us and the App, the choices you make and the products and features you use. The personal information we collect may include the following:
    </p>
    <p>
        <span class="fw-bold">Personal Information Provided by You.</span> We collect names; phone numbers; email addresses; mailing addresses; usernames; passwords; registered animal id; registered license number; and other similar information.
    </p>
    <p>
        All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.
    </p>
    <p class="fw-bold">
        Information automatically collected
    </p>
    <p>
        <span class="fw-bold">In Short:</span>  Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our App.
    </p>
    <p>
        We automatically collect certain information when you visit, use or navigate the App. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our App and other technical information. This information is primarily needed to maintain the security and operation of our App, and for our internal analytics and reporting purposes.
    </p>
    <p>
        The information we collect includes:
        - Log and Usage Data. Log and usage data is service-related, diagnostic, usage and performance information our servers automatically collect when you access or use our App and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity in the App (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).

    </p>
    <p>
        - Device Data. We collect device data such as information about your computer, phone, tablet or other device you use to access the App. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model Internet service provider and/or mobile carrier, operating system and system configuration information.
    </p>
    <p>
        - Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the App. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. Note however, if you choose to opt out, you may not be able to use certain aspects of the Services.
    </p>
    <p class="fw-bold">
        Information collected through our App
    </p>
    <p>
        <span class="fw-bold">In Short:</span>  We collect information regarding your geolocation, mobile device, push notifications, when you use our App.
    </p>
    <p>
        - If you use our App, we also collect the following information:
        Geolocation Information. We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using our App, to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings.
        Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device's camera, and other features. If you wish to change our access or permissions, you may do so in your device's settings.
    </p>
    <p>
        - Mobile Device Data. We automatically collect device information (such as your mobile device ID, model and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our App, we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID and information about the features of our App you accessed.
    </p>
    <p>
        - Push Notifications. We may request to send you push notifications regarding your account or certain features of the App. If you wish to opt-out from receiving these types of communications, you may turn them off in your device's settings.
        This information is primarily needed to maintain the security and operation of our App, for troubleshooting and for our internal analytics and reporting purposes.
    </p>


    <div class="mt-5 fw-bold">
        2. HOW DO WE USE YOUR INFORMATION?
    </div>
    <p>
        <span class="fw-bold">In Short:</span>  We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.
    </p>
    <p>
We use personal information collected via our App for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
    </p>
    <p>
We use the information we collect or receive:
    </p>
    <p>
        <span class="fw-bold">- To facilitate account creation and logon process.</span> If you choose to link your account with us to a third-party account (such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract.
    </p>
    <p>
        <span class="fw-bold">- To post testimonials.</span> We post testimonials on our App that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and the content of the testimonial. If you wish to update, or delete your testimonial, please contact us at support@getpalsapp.com and be sure to include your name, testimonial location, and contact information.
    </p>
    <p>
        <span class="fw-bold">- Request feedback.</span> We may use your information to request feedback and to contact you about your use of our App.
    </p>
    <p>
        <span class="fw-bold">- To enable user-to-user communications.</span> We may use your information in order to enable user-to-user communications with each user's consent.
    </p>
    <p>
        <span class="fw-bold">- To manage user accounts.</span> We may use your information for the purposes of managing our account and keeping it in working order.
    </p>
    <p>
        <span class="fw-bold>- To send administrative information to you.</span> We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.
    </p>
    <p>
        <span class="fw-bold">- To protect our Services.</span> We may use your information as part of our efforts to keep our App safe and secure (for example, for fraud monitoring and prevention).
    </p>
    <p class="fw-bold">
        - To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.
    </p>
    <p>
        <span class="fw-bold">- To respond to legal requests and prevent harm.</span> If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.
    </p>
    <p>
        <span class="fw-bold">- Fulfill and manage your orders.</span> We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the App.
    </p>
    <p>
        <span class='fw-bold'>- Administer prize draws and competitions.</span> We may use your information to administer prize draws and competitions when you elect to participate in our competitions.
    </p>
    <p>
       <span class='fw-bold'> - To deliver and facilitate delivery of services to the user.</span> We may use your information to provide you with the requested service.
    </p>
    <p>
        <span class='fw-bold'>- To respond to user inquiries/offer support to users.</span> We may use your information to respond to your inquiries and solve any potential issues you might have with the use of our Services.
    </p>
    <p>
        <span class='fw-bold'>- To send you marketing and promotional communications.</span> We and/or our third-party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. For example, when expressing an interest in obtaining information about us or our App, subscribing to marketing or otherwise contacting us, we will collect personal information from you. You can opt-out of our marketing emails at any time (see the "WHAT ARE YOUR PRIVACY RIGHTS?" below).
    </p>
    <p>
        <span class='fw-bold'>- Deliver targeted advertising to you.</span> We may use your information to develop and display personalized content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.
    </p>
    <p>
        <span class='fw-bold'>- For other business purposes.</span> We may use your information for other business purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our App, products, marketing and your experience. We may use and store this information in aggregated and anonymized form so that it is not associated with individual end users and does not include personal information. We will not use identifiable personal information without your consent.
    </p>


    <div class="mt-5 fw-bold">
        3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
    </div>
    <p>
        <span class="fw-bold">In Short:</span>  We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.
    </p>
    <p>
        We may process or share your data that we hold based on the following legal basis:
    </p>
    <p>        
        <span class="fw-bold">- Consent:</span> We may process your data if you have given us specific consent to use your personal information for a specific purpose.
    </p>
    <p>        
        <span class="fw-bold">- Legitimate Interests:</span> We may process your data when it is reasonably necessary to achieve our legitimate business interests.
    </p>
    <p>        
        <span class="fw-bold">- Performance of a Contract:</span> Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.
    </p>
    <p>        
        <span class="fw-bold">- Legal Obligations:</span> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
    </p>
    <p>        
        <span class="fw-bold">- Vital Interests:</span> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
    </p>
    <p>        
        - More specifically, we may need to process your data or share your personal information in the following situations:
    </p>
    <p>        
        <span class="fw-bold">- Business Transfers.</span> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
    </p>
    <p>        
        <span class="fw-bold">- Other Users.</span> When you share personal information (for example, by posting comments, contributions or other content to the App) or otherwise interact with public areas of the App, such personal information may be viewed by all users and may be publicly made available outside the App in perpetuity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our App, and view your profile.
    </p>


    <div class="mt-5 fw-bold">
        4. HOW LONG DO WE KEEP YOUR INFORMATION?
    </div>
    <p>
        <span class='fw-bold'>In Short:</span>  We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
    </p>
    <p>
        We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than twelve (12) months past the termination of the user's account.
    </p>
    <p>        
        When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
    </p>


    <div class="mt-5 fw-bold">
        5. HOW DO WE KEEP YOUR INFORMATION SAFE?
    </div>
    <p>
        <span class='fw-bold'>In Short:</span>  We aim to protect your personal information through a system of organizational and technical security measures.
    </p>
    <p>
        We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our App is at your own risk. You should only access the App within a secure environment.
    </p>


    <div class="mt-5 fw-bold">
        6. WHAT ARE YOUR PRIVACY RIGHTS?
    </div>
    <p>
        <span class='fw-bold'>In Short:</span>  You may review, change, or terminate your account at any time.
    </p>
    <p>
        If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: 
        <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm." target="_blank">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</a>
    </p>
    <p>
        If you are a resident in Switzerland, the contact details for the data protection authorities are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
    </p>
    <p>
        If you have questions or comments about your privacy rights, you may email us at support@getpalsapp.com.
    </p>
    <p class="fw-bold">
        Account Information
    </p>
    <p>
        If you would at any time like to review or change the information in your account or terminate your account, you can:
        Log in to your account settings and update your user account.
        Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.
    </p>
    <p>
        Opting out of email marketing: You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list — however, we may still communicate with you, for example to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:          
    </p>
    <div class="mt-5 fw-bold">
        7. CONTROLS FOR DO-NOT-TRACK FEATURES
    </div>
    <p>
        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice. 
    </p>


    <div class="mt-5 fw-bold">
        8. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
    </div>
    <p>
        <span class='fw-bold'>In Short:</span> Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
    </p>
    <p>
        California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
    </p>
    <p>        
        If you are under 18 years of age, reside in California, and have a registered account with the App, you have the right to request removal of unwanted data that you publicly post on the App. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the App, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).
    </p>
    <p class='fw-bold'>        
        CCPA Privacy Notice
    </p>        
    <p>
        The California Code of Regulations defines a "resident" as:
    </p>        
    <ol>
        <li> every individual who is in the State of California for other than a temporary or transitory purpose and</li>
        <li> every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</li>
    </ol>
    <p>        
        All other individuals are defined as "non-residents."
    </p>
    <p>        
        If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.
    </p>
    <p class='fw-bold'>        
        What categories of personal information do we collect?
    </p>
    <p>        
        We have collected the following categories of personal information in the past twelve (12) months:
    </p>
    
    <style>
        table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }
    </style>
    <table>
        <thead>
            <tr>
                <th>
                    Category
                </th>
                <th>
                    Examples
                </th>
                <th>
                    Collected
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    A. Identifiers
                </td>
                <td>
                    Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address and account name
                </td>
                <td>
                    YES
                </td>
            </tr>
            <tr>
                <td>
                    B. Personal information categories listed in the California Customer Records statute
                </td>
                <td>
                    Name, contact information, education, employment, employment history and financial information
                </td>
                <td>
                    YES
                </td>
            </tr>
            <tr>
                <td>
                    C. Protected classification characteristics under California or federal law
                </td>
                <td>
                    Gender and date of birth
                </td>
                <td>
                    YES
                </td>
            </tr>
            <tr>
                <td>
                    D. Commercial information
                </td>
                <td>
                    Transaction information, purchase history, financial details and payment information
                </td>
                <td>
                    YES
                </td>
            </tr>
            <tr>
                <td>
                    E. Biometric information
                </td>
                <td>
                    Fingerprints and voiceprints
                </td>
                <td>
                    NO
                </td>
            </tr>
            <tr>
                <td>
                    F. Internet or other similar network activity
                </td>
                <td>
                    Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems and advertisements
                </td>
                <td>
                    YES
                </td>
            </tr>
            <tr>
                <td>
                    G. Geolocation data
                </td>
                <td>
                    Device location
                </td>
                <td>
                    YES
                </td>
            </tr>
            <tr>
                <td>
                    H. Audio, electronic, visual, thermal, olfactory, or similar information
                </td>
                <td>
                    Images and audio, video or call recordings created in connection with our business activities
                </td>
                <td>
                    YES
                </td>
            </tr>
            <tr>
                <td>
                    I. Professional or employment-related information
                </td>
                <td>
                    Business contact details in order to provide you our services at a business level, job title as well as work history and professional qualifications if you apply for a job with us
                </td>
                <td>
                    NO
                </td>
            </tr>
            <tr>
                <td>
                    J. Education Information
                </td>
                <td>
                    Student records and directory information
                </td>
                <td>
                    NO
                </td>
            </tr>
            <tr>
                <td>
                    K. Inferences drawn from other personal information
                </td>
                <td>
                    Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics
                </td>
                <td>
                    NO
                </td>
            </tr>
        </tbody>
    </table>

    <p>
        We may also collect other personal information outside of these categories instances where you interact with us in-person, online, or by phone or mail in the context of:
        - Receiving help through our customer support channels;
- Participation in customer surveys or contests; and
- Facilitation in the delivery of our Services and to respond to your inquiries.

      </p>
      <p class='fw-bold'>
How do we use and share your personal information?
      </p>
      <p>
More information about our data collection and sharing practices can be found in this privacy notice.
      </p>
      <p>
You may contact us by email at support@getpalsapp.com, or by referring to the contact details at the bottom of this document.
      </p>
      <p>
If you are using an authorized agent to exercise your right to opt-out we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.
      </p>
      <p class='fw-bold'>
Will your information be shared with anyone else?
      </p>
      <p>
We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf.
      </p>
      <p>
We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal data.
      </p>
      <p>
Pals LLC has not disclosed or sold any personal information to third parties for a business or commercial purpose in the preceding 12 months. Pals LLC will not sell personal information in the future belonging to website visitors, users and other consumers.
      </p>
      <p class='fw-bold'>
Your rights with respect to your personal data
      </p>
      <p>
Right to request deletion of the data - Request to delete
      </p>
      <p>
You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation or any processing that may be required to protect against illegal activities.
      </p>
      <p>
Right to be informed - Request to know
      </p>
      <p>
Depending on the circumstances, you have a right to know:
- whether we collect and use your personal information;
- the categories of personal information that we collect;
- the purposes for which the collected personal information is used;
- whether we sell your personal information to third parties;
- the categories of personal information that we sold or disclosed for a business purpose;
- the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and
- the business or commercial purpose for collecting or selling personal information.
      </p>
      <p>
In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.
      </p>
      <p>
Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights
      </p>
      <p>
We will not discriminate against you if you exercise your privacy rights.
      </p>
      <p>
Verification process
      </p>
      <p>
Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g. phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.
      </p>
      <p>
We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. If, however, we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity, and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.
      </p>
      <p>
Other privacy rights
- you may object to the processing of your personal data
- you may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the data
- you can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.
- you may request to opt-out from future selling of your personal information to third parties. Upon receiving a request to opt-out, we will act upon the request as soon as feasibly possible, but no later than 15 days from the date of the request submission.
- To exercise these rights, you can contact us by email at support@getpalsapp.com, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.
    </p>


    <div class="mt-5 fw-bold">
        9. DO WE MAKE UPDATES TO THIS NOTICE?
    </div>
    <p>
    <span class='fw-bold'>In Short:</span>  Yes, we will update this notice as necessary to stay compliant with relevant laws.
      </p>
      <p>
    We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
    </p>


    <div class="mt-5 fw-bold">
        10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
    </div>
    <p>
        If you have questions or comments about this notice, you may email us at support@getpalsapp.com.            
    </p>
    

    <div class="mt-5 fw-bold">
        11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
    </div>
    <p>
        Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please visit: support@getpalsapp.com.
    </p>
    
    `,




    terms: `
    <p>
        Pals is licensed to You(End- User) by Pals LLC (hereinafter: Licensor), for use only under the terms of this License Agreement.
    </p>
    <p>
    By downloading the Application from the Apple AppStore, and any update thereto(as permitted by this License Agreement), You indicate that You agree to be bound by all of the terms and conditions of this License Agreement, and that You accept this License Agreement.
    </p>
    <p>
    The parties of this License Agreement acknowledge that Apple is not a Party to this License Agreement and is not bound by any provisions or obligations with regard to the Application, such as warranty, liability, maintenance and support thereof. Pals LLC, not Apple, is solely responsible for the licensed Application and the content thereof. 
    </p>
    <p>
    This License Agreement may not provide for usage rules for the Application that are in conflict with the latest <a href="https://www.apple.com/legal/internet-services/itunes/us/terms.html" target="_blank">App Store Terms of Service</a>.Pals LLC acknowledges that it had the opportunity to review said terms and this License Agreement is not conflicting with them. All rights not expressly granted to You are reserved.
    </p>

    <div style="margin-top: 30px">
        <b>1. THE APPLICATION</b>
    </div>
    <p>
    Pals (hereinafter: Application) is a piece of software created to facilitate dog activities and conversations via a mobile phone - and customized for Apple mobile devices. It is used to connect dogs on weekly play dates.
    </p>
    <p>
        Furthermore, it is used to discover dog-friendly places in the local community.    
    </p>
    <p>
    The Application is not tailored to comply with industry-specific regulations (Health Insurance Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions would be subjected to such laws, you may not use this Application. You may not use the Application in a way that would violate the Gramm-Leach-Bliley Act (GLBA).
    </p>
    
    <div style="margin-top: 30px">
        <b>2. SCOPE OF LICENSE</b>
    </div>
    <p>
    2.1  You are given a non-transferable, non-exclusive, non-sublicensable license to install and use the Licensed Application on any Apple-branded Products that You (End-User) own or control and as permitted by the Usage Rules set forth in this section and the App Store Terms of Service, with the exception that such licensed Application may be accessed and used by other accounts associated with You (End-User, The Purchaser) via Family Sharing or volume purchasing.

    </p>

    <p>
    2.2  This license will also govern any updates of the Application provided by Licensor that replace, repair, and/or supplement the first Application, unless a separate license is provided for such update in which case the terms of that new license will govern.
    </p>

    <p>
    2.3  You may not share or make the Application available to third parties (unless to the degree allowed by the Apple Terms and Conditions, and with Pals LLC's prior written consent), sell, rent, lend, lease or otherwise redistribute the Application.
    </p>

    <p>
    2.4  You may not reverse engineer, translate, disassemble, integrate, decompile, integrate, remove, modify, combine, create derivative works or updates of, adapt, or attempt to derive the source code of the Application, or any part thereof (except with Pals LLC's prior written consent).
    </p>

    <p>
    2.5  You may not copy (excluding when expressly authorized by this license and the Usage Rules) or alter the Application or portions thereof. You may create and store copies only on devices that You own or control for backup keeping under the terms of this license, the App Store Terms of Service, and any other terms and conditions that apply to the device or software used. You may not remove any intellectual property notices. You acknowledge that no unauthorized third parties may gain access to these copies at any time.
    </p>

    <p>
    2.6  Violations of the obligations mentioned above, as well as the attempt of such infringement, may be subject to prosecution and damages.
    </p>

    <p>
    2.7  Licensor reserves the right to modify the terms and conditions of licensing.
    </p>

    <p>
    2.8  Nothing in this license should be interpreted to restrict third-party terms. When using the Application, You must ensure that You comply with applicable third-party terms and conditions.                
    </p>


    <div style="margin-top: 30px">
        <b>3. TECHNICAL REQUIREMENTS</b>
    </div>
    <p>
    3.1  The Application requires a firmware version 1.0.0 or higher. Licensor recommends using the latest version of the firmware.
    </p>

    <p>
3.2  Licensor attempts to keep the Application updated so that it complies with modified/new versions of the firmware and new hardware. You are not granted rights to claim such an update.
</p>

<p>
3.3  You acknowledge that it is Your responsibility to confirm and determine that the app end-user device on which You intend to use the Application satisfies the technical  specifications mentioned above.
</p>

<p>
3.4  Licensor reserves the right to modify the technical specifications as it sees appropriate at any time.
    </p>


    <div style="margin-top: 30px">
        <b>5. USE OF DATA</b>
    </div>
    <p>
        You acknowledge that Licensor will be able to access and adjust Your downloaded licensed Application content and Your personal information, and that Licensor's use of such material and information is subject to Your legal agreements with Licensor and Licensor's privacy policy: <a href="https://getpalsapp.com/privacy-policy" target="_blank">https://getpalsapp.com/privacy-policy</a>.
    </p>


    <div style="margin-top: 30px">
        <b>6. USER GENERATED CONTRIBUTIONS</b>
    </div>
    <p>
    The Application may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or in the Application, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, "Contributions"). Contributions may be viewable by other users of the Application and through third-party websites or applications. As such, any Contributions you transmit may be treated as non-confidential and non-proprietary. When you create or make available any Contributions, you thereby represent and warrant that:
    </p>

    <p>
    1. The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.
    </p>
    <p>
    2. You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the Application, and other users of the Application to use your Contributions in any manner contemplated by the Application and these Terms of Use.
    </p>
    <p>
    3. You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness or each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Application and these Terms of Use.
    </p>
    <p>
    4. Your Contributions are not false, inaccurate, or misleading.
    </p>
    <p>
    5. Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.
    </p>
    <p>
    6. Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us).
    </p>
    <p>
    7. Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.
    </p>
    <p>
    8. Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or class of people.
    </p>
    <p>
    9. Your Contributions do not violate any applicable law, regulation, or rule.
    </p>
    <p>
    10. Your Contributions do not violate the privacy or publicity rights of any third party.
    </p>
    <p>
    11. Your Contributions do not contain any material that solicits personal information from anyone under the age of 18 or exploits people under the age of 18 in a sexual or violent manner.
    </p>
    <p>
    12. Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.
    </p>
    <p>
    13. Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.
    </p>
    <p>
    14. Your Contributions do not otherwise violate, or link to material that violates, any provision of these Terms of Use, or any applicable law or regulation.
    </p>
    <p>  
    Any use of the Application in violation of the foregoing violates these Terms of Use and may result in, among other things, termination or suspension of your rights to use the Application.        
    </p>

    <div style="margin-top: 30px">
        <b>7. CONTRIBUTION LICENSE</b>
    </div>
    <p>
    By posting your Contributions to any part of the Application or making Contributions accessible to the Application by linking your account from the Application to any of your social networking accounts, you automatically grant, and you represent and warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to host, use copy, reproduce, disclose, sell, resell, publish, broad cast, retitle, archive, store, cache, publicly display, reformat, translate, transmit, excerpt (in whole or in part), and distribute such Contributions (including, without limitation, your image and voice) for any purpose, commercial advertising, or otherwise, and to prepare derivative works of, or incorporate in other works, such as Contributions, and grant and authorize sublicenses of the foregoing. The use and distribution may occur in any media formats and through any media channels.
    </p>

    <p>
    This license will apply to any form, media, or technology now known or hereafter developed, and includes our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide. You waive all moral rights in your Contributions, and you warrant that moral rights have not otherwise been asserted in your Contributions.
    </p>
    
    <p>
    We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area in the Application. You are solely responsible for your Contributions to the Application and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.
    </p>
    
    <p>
    We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any Contributions; (2) to re-categorize any Contributions to place them in more appropriate locations in the Application; and (3) to pre-screen or delete any Contributions at any time and for any reason, without notice. We have no obligation to monitor your Contributions.
    </p>


    <div style="margin-top: 30px">
        <b>8. DISCLAIMER</b>
    </div>
    <p>
        The information provided by Pals LLC (“we,” “us”, or “our”) on <a href="http://getpalsapp.com" target="_blank">http://getpalsapp.com</a> (the “Site”) and our mobile application is for general informational purposes only. All information on the Site and our mobile application is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site or our mobile application. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE AND OUR MOBILE APPLICATION. YOUR USE OF THE SITE AND OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.        
    </p>

    <div style="margin-top: 30px">
        <b>9. LIABILITY</b>
    </div>
    <p>
        9.1  Licensor's responsibility in the case of violation of obligations and tort shall be limited to intent and gross negligence. Only in case of a breach of essential contractual duties (cardinal obligations), Licensor shall also be liable in case of slight negligence. In any case, liability shall be limited to the foreseeable, contractually typical damages. The limitation mentioned above does not apply to injuries to life, limb, or health.
    </p>
    <p>
        9.2  Licensor takes no accountability or responsibility for any damages caused due to a breach of duties according to Section 2 of this Agreement. To avoid data loss, You are required to make use of backup functions of the Application to the extent allowed by applicable third-party terms and conditions of use. You are aware that in case of alterations or manipulations of the Application, You will not have access to licensed Application.               
    </p>

    <div style="margin-top: 30px">
        <b>10. WARRANTY</b>
    </div>
    <p>
        10.1  Licensor warrants that the Application is free of spyware, trojan horses, viruses, or any other malware at the time of Your download. Licensor warrants that the Application works as described in the user documentation.
    </p>
    <p>
        10.2  No warranty is provided for the Application that is not executable on the device, that has been unauthorizedly modified, handled inappropriately or culpably, combined or installed with inappropriate hardware or software, used with inappropriate accessories, regardless if by Yourself or by third parties, or if there are any other reasons outside of Pals LLC's sphere of influence that affect the executability of the Application.
    </p>
    <p>    
        10.3  You are required to inspect the Application immediately after installing it and notify Pals LLC about issues discovered without delay by e-mail provided in <a href="https://app.termly.io/document/terms-of-use-for-ios-app49685aa2-d262-4d78-84e7-242f746fe5b4#productclaims" target="_blank">Product Claims.</a> The defect report will be taken into consideration and further investigated if it has been mailed within a period of ninety (90) days after discovery.
    </p>    
        10.4  If we confirm that the Application is defective, Pals LLC reserves a choice to remedy the situation either by means of solving the defect or substitute delivery.
    <p>        
        10.5  In the event of any failure of the Application to conform to any applicable warranty, You may notify the App-Store-Operator, and Your Application purchase price will be refunded to You. To the maximum extent permitted by applicable law, the App-Store-Operator will have no other warranty obligation whatsoever with respect to the App, and any other losses, claims, damages, liabilities, expenses and costs attributable to any negligence to adhere to any warranty.
    </p>
    <p>    
        10.6  If the user is an entrepreneur, any claim based on faults expires after a statutory period of limitation amounting to twelve (12) months after the Application was made available to the user. The statutory periods of limitation given by law apply for users who are consumers.      
    </p>

    <div style="margin-top: 30px">
        <b>11. PRODUCT CLAIMS</b>
    </div>
    <p>
        Pals LLC and the End-User acknowledge that Pals LLC, and not Apple, is responsible for addressing any claims of the End-User or any third party relating to the licensed Application or the End-User’s possession and/or use of that licensed Application, including, but not limited to:
    </p>
    <p>
        (i) product liability claims;
    </p>
    <p>        
        (ii) any claim that the licensed Application fails to conform to any applicable legal or regulatory requirement; and
    </p>
    <p>    
        (iii) claims arising under consumer protection, privacy, or similar legislation, including in connection with Your Licensed Application’s use of the HealthKit and HomeKit.
    </p>

    <div style="margin-top: 30px">
        <b>12. LEGAL COMPLIANCE</b>
    </div>
    <p>
        You represent and warrant that You are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a "terrorist supporting" country; and that You are not listed on any U.S. Government list of prohibited or restricted parties.       
    </p>

    <div style="margin-top: 30px">
        <b>13. CONTACT INFORMATION</b>
    </div>
    <p>
        For general inquiries, complaints, questions or claims concerning the licensed Application, please contact support@getpalsapp.com.             
    </p>


    <div style="margin-top: 30px">
        <b>14. TERMINATION</b>
    </div>
    <p>
        The license is valid until terminated by Pals LLC or by You. Your rights under this license will terminate automatically and without notice from Pals LLC if You fail to adhere to any term(s) of this license. Upon License termination, You shall stop all use of the Application, and destroy all copies, full or partial, of the Application.       
    </p>

    <div style="margin-top: 30px">
        <b>15. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY</b>
    </div>
    <p>
        Pals LLC represents and warrants that Pals LLC will comply with applicable third-party terms of agreement when using licensed Application.
    </p>
    <p>
        In Accordance with Section 9 of the "Instructions for Minimum Terms of Developer's End-User License Agreement," Apple and Apple's subsidiaries shall be third-party beneficiaries of this End User License Agreement and - upon Your acceptance of the terms and conditions of this license agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce this End User License Agreement against You as a third-party beneficiary thereof.            
    </p>
    
    <div style="margin-top: 30px">
        <b>16. INTELLECTUAL PROPERTY RIGHTS</b>
    </div>
    <p>
        Pals LLC and the End-User acknowledge that, in the event of any third-party claim that the licensed Application or the End-User's possession and use of that licensed Application infringes on the third party's intellectual property rights, Pals LLC, and not Apple, will be solely responsible for the investigation, defense, settlement and discharge or any such intellectual property infringement claims.   
    </p>
    
    <div style="margin-top: 30px">
        <b>17. APPLICABLE LAW</b>
    </div>
    <p>
        This license agreement is governed by the laws of the Commonwealth of Virginia excluding its conflicts of law rules.       
    </p>

    <div style="margin-top: 30px">
        <b>18. MISCELLANEOUS</b>
    </div>
    <p>
        18.1  If any of the terms of this agreement should be or become invalid, the validity of the remaining provisions shall not be affected. Invalid terms will be replaced by valid ones formulated in a way that will achieve the primary purpose.
    </p>
    <p>  
        18.2  Collateral agreements, changes and amendments are only valid if laid down in writing. The preceding clause can only be waived in writing.             
    </p>
    `,
}

export default data;