import { useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';

function ScrollToTop({ children }) {

    const location = useLocation();

    useEffect(() => {
        if (!location.hash) {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return (children);
}

export default withRouter(ScrollToTop);