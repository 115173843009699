import React from 'react';

// screens
import Policy from './components/Policy';
import ThanksContact from './components/ThanksContact';

// css imports
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/styles.css';
import './assets/css/responsive.css';
import 'swiper/swiper-bundle.css';

// routing
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from 'react-router-dom';

// common components
import Layout from './consts/common/Layout';
import LandingPage from './components/LandingPage';
import ScrollToTop from './consts/common/ScrollToTop';
import RouteProvider from './RouteProvider';

function App() {
	return (
		<Router>
			<Layout>
				<RouteProvider>
					<ScrollToTop>
						<Switch>
							<Route exact path="/" component={LandingPage} />
							<Route path="/thanks" component={ThanksContact} />
							<Route path="/privacy" render={routeProps => (
								<Policy {...routeProps} type="privacy" />
							)} />
							<Route path="/terms" render={routeProps => (
								<Policy {...routeProps} type="terms" />
							)} />
						</Switch>
					</ScrollToTop>
				</RouteProvider>
			</Layout>
		</Router>
	);
}

export default App;