import { Col, Row } from "react-bootstrap";
import { Element } from 'react-scroll';
import CommentSvg from '../../../assets/svgs/commentSvg';

export default function AboutPals() {
    return (
        <Element name="about" className=" mt-0 mt-lg-5">
            <Row className="mt-5">
                <Col sm={12} className="text-center fw-bold head-text">ABOUT PALS</Col>
                <Col sm={12} className="text-center sub-head-text">Sit. Swipe. Play.</Col>
                <Col sm={12} className="text-center sub-text-peragraph">Pals is a modern approach to connecting dog owners in your community. The app provides a platform to quickly and safely connect, schedule playdates and find dog-friendly locations in your area. Check out our special features below!</Col>
            </Row>
            <Row className="margin-top gx-1">
                <Col sm={12} md={6} xl={3} className="border-bg-shadow">
                    <div className="about-card">
                        <div className="card-icons mb-4">
                            <i className="fas fa-paw about-icon" />
                        </div>
                        <div className="card-header-text text-center text-md-start">
                            Discover
                        </div>
                        <div className="card-subtext text-center text-md-start">
                            Connect with pals in your area. Swipe the toggle left to pass or right to Play!
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={6} xl={3} className="border-bg-shadow">
                    <div className="about-card">
                        <div className="card-icons mb-4">
                            <i className="fas fa-map-marker-alt about-icon" />
                        </div>
                        <div className="card-header-text text-center text-md-start">
                            Explore
                        </div>
                        <div className="card-subtext text-center text-md-start">
                            Check out the latest parks, trails, restaurants and more! Share your favorite location or bookmark to save for later.
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={6} xl={3} className="border-bg-shadow">
                    <div className="about-card">
                        <div className="card-icons mb-4">
                            <CommentSvg />
                        </div>
                        <div className="card-header-text text-center text-md-start">
                            Chat
                        </div>
                        <div className="card-subtext text-center text-md-start">
                            View your matches and message new pals to set up play dates! You can even check-in and rate your experience!
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={6} xl={3} className="border-bg-shadow">
                    <div className="about-card">
                        <div className="card-icons mb-4">
                            <i className="fas fa-user-circle about-icon"></i>
                        </div>
                        <div className="card-header-text text-center text-md-start">
                            Customize
                        </div>
                        <div className="card-subtext text-center text-md-start">
                            Add new photos of your pal or update your preferences and settings to your liking!
                        </div>
                    </div>
                </Col>
            </Row>
        </Element>
    )
}